import { ChunkInitializationService } from 'app/chunk-initialization/chunk-initialization.service';
import { ConstantImage } from 'app/shared/enum/constant-image';
import { PermissionService } from 'app/shared/service/permission.service';
import { SpinnerService } from 'app/spinner/spinner.service';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { PreLoaderService } from './service/preloader.service';
import { SnackbarService } from './service/snack-bar.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MobileAccountMenuComponent } from 'app/user_app/mobile-account-menu/mobile-account-menu.component';
declare let require: any;

export class Util {
    static readonly permission = new PermissionService();
    static constantImage = ConstantImage;
    static readonly appVersion = require('../../../package.json').version;
    static readonly spinner = new SpinnerService();
    static readonly preLoader = new PreLoaderService();
    static readonly chunkService = new ChunkInitializationService();
    static filterArray(arr: any[], key: string, value: any) {
        if (arr && arr.length > 0) {
            const obj = arr.filter((trans) => trans[key] === value);
            return obj[0];
        }
        return {};
    }

    static rendition(renditions: any[], value: number) {
        let rendition = Util.filterArray(renditions, 'width', value);
        if (!rendition) {
            rendition = Util.filterArray(renditions, 'is_master', true);
        }
        return rendition.source;
    }

    static sortByKey(array, key) {
        return array.sort(function (a, b) {
            const x = a[key];
            const y = b[key];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    static metaData(arr: any[]) {
        let metaData = Util.filterArray(
            arr,
            'language',
            localStorage.getItem('translate_language'),
        );
        if (!metaData) {
            metaData = Util.filterArray(arr, 'default', true);
        }
        return metaData;
    }

    static getEnumArray(ENUM: any, keyValue: boolean): string[] {
        if (!keyValue) {
            return Object.keys(ENUM).filter((k) => typeof ENUM[k as any] === 'number');
        } else {
            return Object['values'](ENUM);
        }
    }

    static defaultAssetImage(row, supportedAssetTypeArray) {
        if (supportedAssetTypeArray.indexOf(row.type) === -1) {
            return true;
        } else {
            return false;
        }
    }

    static isOnline(): boolean {
        return navigator.onLine;
    }

    static isOffline(): boolean {
        return JSON.parse(localStorage.getItem('offline'));
    }

    static setFileThumbnails(url, className, remoteConfig?, size?) {
        const token = JSON.parse(localStorage.getItem('auth_token'));
        const authToken = `${token.token_type} ${token.access_token}`;
        let axiosConfig: any;
        axiosConfig = {
            headers: {
                Authorization: authToken,
            },
            responseType: 'blob',
        };
        axios.get(url, axiosConfig).then((response) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function () {
                const imageDataUrl: any = reader.result;
                const imageElement: any = document.querySelector(className);
                if (imageElement !== null) {
                    imageElement.style.backgroundImage = "url('" + imageDataUrl + "')";
                    if (size) {
                        imageElement.style.backgroundSize = size;
                        imageElement.style.backgroundColor = 'black';
                    } else {
                        imageElement.style.backgroundSize = 'cover';
                    }
                }
            };
        });
    }

    static removeDuplicateObject(arraykey, array) {
        const newObject = new Set();
        return array.filter(function (x) {
            const key = arraykey(x),
                isNew = !newObject.has(key);
            if (isNew) {
                newObject.add(key);
            }
            return isNew;
        });
    }

    static getSSOAuthType(supportedAuthTypes) {
        const authTypeArr = supportedAuthTypes.split(',');
        let SsoAuthType = 'DEFAULT';
        authTypeArr.forEach((value: any) => {
            if (value !== 'DEFAULT') {
                SsoAuthType = value;
            }
        });
        return SsoAuthType;
    }

    static checkForSpecialChar(text) {
        if (text && (text.startsWith('+') || text.startsWith('@') || text.startsWith('='))) {
            return true;
        } else {
            return false;
        }
    }
    static getUTCDateOrdinal(d: any) {
        const currentYear = new Date().getFullYear();
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const date = new Date(d),
            day = date.getUTCDate(),
            month = date.getUTCMonth(),
            s = ['th', 'st', 'nd', 'rd'],
            v = day % 100;
        return `${day + (s[(v - 20) % 10] || s[v] || s[0])} ${monthNames[month]} ${new Date(d).getFullYear() !== currentYear ? new Date(d).getFullYear() : ''}`;
    }

    static isFutureOrPastYear(startTime: string): boolean {
        const startDate = new Date(startTime);
        return startDate.getFullYear() !== new Date().getFullYear();
    }

    static displayLbUTCFrequency(sd: any, ed: any, lbType) {
        const currentYear = new Date().getUTCFullYear();
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        const monthFullNames = [
            'January',
            'February',
            'March',
            '	April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        const startDate = new Date(sd),
            startDateDay = startDate.getUTCDate(),
            startDateMonth = startDate.getUTCMonth(),
            startDateOrdinal = ['th', 'st', 'nd', 'rd'],
            startDateVal = startDateDay % 100;
        const endDate = new Date(ed),
            endDateDay = endDate.getUTCDate(),
            endDateMonth = endDate.getUTCMonth(),
            endDateOrdinal = ['th', 'st', 'nd', 'rd'],
            endDateVal = endDateDay % 100;
        switch (lbType) {
            case 'FORTNIGHT':
                return `${startDateDay + (startDateOrdinal[(startDateVal - 20) % 10] || startDateOrdinal[startDateVal] || startDateOrdinal[0])} - ${endDateDay + (endDateOrdinal[(endDateVal - 20) % 10] || endDateOrdinal[endDateVal] || endDateOrdinal[0])} ${monthNames[endDateMonth]} ${new Date(ed).getUTCFullYear() !== currentYear ? new Date(ed).getUTCFullYear() : ''}`;
            case 'MONTHLY':
                return new Date(sd).getUTCFullYear() !== currentYear
                    ? monthNames[startDateMonth] + ' ' + new Date(ed).getUTCFullYear()
                    : monthFullNames[startDateMonth];
            case 'DYNAMIC':
                if (
                    new Date(sd).getUTCFullYear() === new Date(ed).getUTCFullYear() &&
                    new Date(sd).getUTCFullYear() === currentYear &&
                    new Date(ed).getUTCFullYear() === currentYear
                ) {
                    return `${startDateDay + (startDateOrdinal[(startDateVal - 20) % 10] || startDateOrdinal[startDateVal] || startDateOrdinal[0])} ${monthNames[startDateMonth]} - ${endDateDay + (endDateOrdinal[(endDateVal - 20) % 10] || endDateOrdinal[endDateVal] || endDateOrdinal[0])} ${monthNames[endDateMonth]}`;
                } else {
                    return `${startDateDay + (startDateOrdinal[(startDateVal - 20) % 10] || startDateOrdinal[startDateVal] || startDateOrdinal[0])} ${monthNames[startDateMonth]} ${new Date(sd).getUTCFullYear()} - ${endDateDay + (endDateOrdinal[(endDateVal - 20) % 10] || endDateOrdinal[endDateVal] || endDateOrdinal[0])} ${monthNames[endDateMonth]} ${new Date(ed).getUTCFullYear()}`;
                }
            case 'QUARTERLY':
            case 'BIANNUAL':
            case 'ANNUAL':
                return new Date(sd).getUTCFullYear() !== currentYear
                    ? monthNames[startDateMonth] +
                          ' ' +
                          new Date(sd).getUTCFullYear() +
                          ' - ' +
                          monthNames[endDateMonth] +
                          ' ' +
                          new Date(ed).getUTCFullYear()
                    : monthNames[startDateMonth] + ' - ' + monthNames[endDateMonth];
            default:
                break;
        }
    }

    static getDateOrdinal(d: any) {
        const date = new Date(d),
            day = date.getUTCDate(),
            s = ['th', 'st', 'nd', 'rd'],
            v = day % 100;
        return day + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    static getNumberOrdinal(number) {
        const ordinalKey = ['th', 'st', 'nd', 'rd'],
            value = number % 100;
        return number + (ordinalKey[(value - 20) % 10] || ordinalKey[value] || ordinalKey[0]);
    }

    static getUserInitials(firstName?: string, lastName?: string) {
        const fname = firstName || '';
        const lname = lastName || '';
        const usrname = `${fname} ${lname}`;
        const usrNameSliced = usrname.split(/\s+/).slice(0, 2).join(' ');
        return usrNameSliced.split(/\s/).reduce((res, word) => (res += word.slice(0, 1)), '');
    }
    static scrollToTop(timeout?: number) {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, timeout);
    }
    static downloadFile(data: any, fileUrl, remoteConfig, fileName?, snackService?) {
        if (fileName) {
            data.name = fileName;
        } else {
            fileName = fileUrl.slice(fileUrl.lastIndexOf('/') + 1).toLowerCase();
            data.name = fileName.slice(0, fileName.lastIndexOf('.'));
        }
        const fileFormat = fileUrl.slice(fileUrl.lastIndexOf('.') + 1).toLowerCase();
        data.file_type = fileFormat;
        const blob = null;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', fileUrl);
        xhr.responseType = 'blob'; // force the HTTP response, response-type header to be blob
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader(
            'Access-Control-Allow-Headers',
            'Origin, X-Requested-With, Content-Type, Accept',
        );
        const token = JSON.parse(localStorage.getItem('auth_token'));
        const authToken = `${token.token_type} ${token.access_token}`;
        xhr.setRequestHeader('Authorization', authToken);
        this.downloadFileWithHeader(data, xhr, snackService);
    }
    static downloadFileWithHeader(data, xhr, snackService?) {
        const spinner = document.createElement('div');
        spinner.classList.add('my-loader');
        document.body.appendChild(spinner);
        let blob = null;
        xhr.onload = function () {
            if (xhr.status === 200) {
                document.body.removeChild(spinner);
                if (snackService) {
                    snackService.success('File Downloaded Successfully');
                }
            }
            blob = xhr.response; // xhr.response is now a blob object
            saveAs(blob, `${data.name}.${data.file_type}`);
        };
        xhr.onerror = function () {
            document.body.removeChild(spinner);
            if (snackService) {
                snackService.error('File Download Failed');
            }
        };
        xhr.send();
    }

    static setImageSrc(remoteConfig, url, imageElement) {
        const token = JSON.parse(localStorage.getItem('auth_token'));
        const authToken = `${token.token_type} ${token.access_token}`;
        let axiosConfig: any;
        axiosConfig = {
            headers: {
                Authorization: authToken,
            },
            responseType: 'blob',
        };
        axios
            .get(url, axiosConfig)
            .then((response) => {
                const reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = function () {
                    const imageDataUrl: any = reader.result;
                    imageElement.setAttribute('src', imageDataUrl);
                };
            })
            .catch((err) => {
                url = true;
                imageElement.setAttribute('src', 'assets/img/user.svg');
            });
    }

    static parseIsoDuration(isoDuration: string, showUnits: boolean = true) {
        const isoRegex = /P(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?/;
        const matchValue = isoRegex.exec(isoDuration);
        if (!matchValue) {
            console.log('Invalid ISO duration');
            return '';
        }
        const days = matchValue[1] ? parseInt(matchValue[1], 10) : 0;
        const hours = matchValue[2] ? parseInt(matchValue[2], 10) : 0;
        const minutes = matchValue[3] ? parseInt(matchValue[3], 10) : 0;
        return Util.formatDuration(days, hours, minutes, showUnits);
    }

    static formatDuration(
        days: number,
        hours: number,
        minutes: number,
        showUnits: boolean,
    ): string {
        const value: string[] = [];
        let dayString = '';
        if (days > 0) {
            const dayUnit = days > 1 ? 's' : '';
            dayString = showUnits ? `${days} Day${dayUnit}` : `${days}`;
            value.push(dayString);
        }
        let hourString = '';
        if (hours > 0) {
            const hourUnit = hours > 1 ? 's' : '';
            hourString = showUnits ? `${hours} Hour${hourUnit}` : `${hours}`;
            value.push(hourString);
        }
        let minuteString = '';
        if (minutes > 0) {
            const minuteUnit = minutes > 1 ? 's' : '';
            minuteString = showUnits ? `${minutes} Minute${minuteUnit}` : `${minutes}`;
            value.push(minuteString);
        }
        return value.join(' ').trim();
    }

    static async privilegeBasedModule(router: any) {
        this.permission.reload();
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        const previousUrl = localStorage.getItem('previous_url');
        if (currentUser.privileges.length > 0) {
            if (/admin/.test(previousUrl)) {
                this.redirectToAdmin(router);
            } else if (/app/.test(previousUrl)) {
                this.redirectToLearner(router);
            } else if (/monitor/.test(previousUrl)) {
                this.redirectToMonitor(router);
            } else if (/review/.test(previousUrl)) {
                this.redirectToReview(router);
            } else if (/form/.test(previousUrl)) {
                this.redirectToForms(router);
            } else if (
                this.permission.has(['node:view']) &&
                this.permission.has(['monitor:usage'])
            ) {
                this.redirectToMonitor(router);
            } else {
                this.redirectToLearner(router);
            }
        } else {
            this.redirectToLearner(router);
        }
    }

    static async redirectToAdmin(router: any) {
        this.chunkService.start();
        await this.chunkService.downloadModule('admin', router);
        this.chunkService.stop();
        if (
            !this.permission.has(['node:view']) &&
            this.permission.has(['topic:*', 'bucket:*', 'sequence:*', 'survey:*'])
        ) {
            const width = window.innerWidth;
            if (width <= 550) {
                this.redirectToLearner(router);
            } else {
                router.navigate(['/admin/manageContent/file']);
            }
        } else {
            localStorage.removeItem('adminActiveSubTab');
            router.navigate(['/admin/manage']);
        }
    }

    static async redirectToLearner(router: any) {
        this.chunkService.start();
        await this.chunkService.downloadModule('learner', router);
        this.chunkService.stop();
        router.navigate(['/app/home']);
    }

    static openBottomSheet(bottomSheet, listType) {
        const bottomSheetRef = bottomSheet.open(MobileAccountMenuComponent, {
            scrollStrategy: new NoopScrollStrategy(),
            panelClass:
                listType === 'normalList' ? 'mobile-footer-menu' : 'external-mobile-footer-menu',
            data: {
                listType: listType,
            },
        });
        bottomSheetRef.afterOpened().subscribe(() => {
            sessionStorage.setItem('mobileMenuBottomOpen', 'true');
        });

        bottomSheetRef.afterDismissed().subscribe(() => {
            sessionStorage.setItem('mobileMenuBottomOpen', 'false');
        });
    }

    static async redirectToMonitor(router: any) {
        this.chunkService.start();
        await this.chunkService.downloadModule('monitor', router);
        this.chunkService.stop();
        if (this.permission.has(['node:view']) && this.permission.has(['monitor:usage'])) {
            if (this.isMobileView(997)) {
                router.navigate(['/app/learnerInsight']);
            } else {
                router.navigate(['/monitor/dashboard']);
            }
        } else {
            router.navigate(['/monitor/completion/content']);
        }
    }

    static isMobileView(scrrensSize) {
        const width = window.innerWidth;
        if (width <= scrrensSize) {
            return 'true';
        }
    }

    static async redirectToForms(router: any) {
        this.chunkService.start();
        await this.chunkService.downloadModule('form', router);
        this.chunkService.stop();
        router.navigate(['/form/listing']);
    }

    static getKeyByValue<enumFile>(enumObj: enumFile, value: string): keyof enumFile | undefined {
        for (const key in enumObj) {
            if (Object.prototype.hasOwnProperty.call(enumObj, key)) {
                if (enumObj[key as keyof enumFile] === value) {
                    return key as keyof enumFile;
                }
            }
        }
        return undefined;
    }

    static async redirectToReview(router: any) {
        this.chunkService.start();
        await this.chunkService.downloadModule('review', router);
        this.chunkService.stop();
        router.navigate(['/app/home']);
        if (this.permission.has(['review.workflow:assess'])) {
            router.navigate(['/review/teamReviews']);
        } else {
            router.navigate(['/review/dashboard']);
        }
    }

    static getCurrentTimeInUTC(offset: number = 0, isPositiveOffset: boolean = true): string {
        const currentDate = new Date();
        const adjustedMinutes = isPositiveOffset ? offset : -offset;
        currentDate.setMinutes(currentDate.getMinutes() + adjustedMinutes);
        return currentDate.toISOString();
    }

    static displayAdminSideBar() {
        return (
            this.permission.has(['monitor:completion']) ||
            this.displayAdmin() ||
            this.displayReview()
        );
    }

    static getUser() {
        return JSON.parse(localStorage.getItem('current_user'));
    }

    static displayAdmin() {
        return this.getUser().privileges.length > 0;
    }

    static goToPointsBadges(router, type: string, from: string) {
        const fragment = type === 'badges' ? 'badgesInsights' : 'pointsInsights';
        const insightBackRoute = from === 'home' ? 'fromHome' : 'fromMyAcc';
        router.navigate(['/app/learnerInsight'], { fragment });
        sessionStorage.setItem('insightBackRoute', insightBackRoute);
    }

    static displayReview() {
        const orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
        if (Util.filterArray(orgSetting.settings, 'property', 'review_enabled') !== undefined) {
            return Util.filterArray(orgSetting.settings, 'property', 'review_enabled').value;
        } else {
            return false;
        }
    }

    static replaceTopicToQuiz(errorMessage, snackbarService: SnackbarService) {
        if (errorMessage.includes('topic')) {
            errorMessage = errorMessage.replace(/topic/g, 'quiz');
        }
        if (errorMessage.includes('Topic')) {
            errorMessage = errorMessage.replace(/Topic/g, 'Quiz');
        }
        if (errorMessage.includes('Topics')) {
            errorMessage = errorMessage.replace(/Topics/g, 'Quizzes');
        }
        if (errorMessage.includes('topics')) {
            errorMessage = errorMessage.replace(/topics/g, 'quizzes');
        }
        snackbarService.error(errorMessage);
    }

    static replaceAssessmentToTest(errorMessage, snackbarService: SnackbarService) {
        if (errorMessage.includes('assessment')) {
            errorMessage = errorMessage.replace(/assessment/g, 'test');
        }
        if (errorMessage.includes('Assessment')) {
            errorMessage = errorMessage.replace(/Assessment/g, 'Test');
        }
        if (errorMessage.includes('Assessments')) {
            errorMessage = errorMessage.replace(/Assessments/g, 'Tests');
        }
        if (errorMessage.includes('assessments')) {
            errorMessage = errorMessage.replace(/assessments/g, 'tests');
        }
        snackbarService.error(errorMessage);
    }

    static displayMonitor() {
        return this.permission.has(['monitor:completion']);
    }

    static applytheme(orgTheme) {
        orgTheme = JSON.parse(orgTheme);
        Object.keys(orgTheme?.colors).forEach((key) => {
            document.documentElement.style.setProperty(`--${key}`, orgTheme.colors[key]);
        });
    }

    static checkValidAuthToken() {
        let tokenObj = {};
        try {
            const token = localStorage.getItem('auth_token');
            tokenObj = JSON.parse(token);
        } catch (e) {
            localStorage.removeItem('auth_token');
        }
        return tokenObj;
    }

    static checkScreenResolution() {
        const width = window.innerWidth;
        if (width >= 1024) {
            return false;
        } else {
            return true;
        }
    }

    static handleAvatarFailed(event) {
        event.target.src = 'assets/img/user.svg';
    }

    static getFileTypeIcon(file) {
        switch (file.type) {
            case 'VIDEO': {
                return 'assets/img/typeVideo.png';
            }
            case 'AUDIO': {
                return 'assets/img/typeAudio.png';
            }
            case 'URL': {
                if (file.url_type === 'VIDEO') {
                    return 'assets/img/typeVideo.png';
                } else {
                    return 'assets/img/typeLink.png';
                }
            }
            case 'PDF': {
                return 'assets/img/typePdf.png';
            }
            case 'SCORM': {
                return 'assets/img/typeScorm.png';
            }
            case 'IMAGE': {
                return 'assets/img/typeImage.png';
            }
            case 'TEXT': {
                return 'assets/img/typeText.png';
            }
            default:
                break;
        }
    }

 
    static isCurrentUrlMatching(value: string, isHash: boolean = false): boolean {
        const currentUrl = isHash
            ? window.location.pathname + window.location.hash
            : window.location.pathname;
        return currentUrl.includes(value);
    }

    static getOrgSettingValue(orgSettingProperty) {
        const orgSetting = JSON.parse(localStorage.getItem('orgSetting'));
        return Util.filterArray(orgSetting.settings, 'property', orgSettingProperty).value;
    }

    static getURLParamValue(url, key) {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        const entryId = params.get(key);
        return entryId;
    }

    static checkFileNameCharCount(file: File, snackbarService: SnackbarService, options?): boolean {
        const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.'));
        const maxFileNameLength = options?.charCount ?? 200;
        if (fileNameWithoutExtension.length > maxFileNameLength) {
            snackbarService.error(`File name should have at most ${maxFileNameLength} characters.`);
            return true;
        } else {
            return false;
        }
    }

    static getItemsTimeFrame(createdAt) {
        const eventDate = new Date(createdAt);
        const now = new Date();

        const startOfToday = new Date(now);
        startOfToday.setUTCHours(0, 0, 0, 0);

        const startOfYesterday = new Date(startOfToday);
        startOfYesterday.setUTCDate(startOfYesterday.getUTCDate() - 1);

        const endOfYesterday = new Date(startOfToday);
        endOfYesterday.setUTCHours(23, 59, 59, 999);

        const startOfThisWeek = new Date(startOfToday);
        startOfThisWeek.setUTCDate(startOfToday.getUTCDate() - startOfToday.getUTCDay());

        const startOfLastWeek = new Date(startOfThisWeek);
        startOfLastWeek.setUTCDate(startOfThisWeek.getUTCDate() - 7);

        const endOfLastWeek = new Date(startOfThisWeek);
        endOfLastWeek.setUTCDate(startOfThisWeek.getUTCDate() - 1);

        const startOfThisMonth = new Date(startOfToday);
        startOfThisMonth.setUTCDate(1);

        const startOfLastMonth = new Date(startOfThisMonth);
        startOfLastMonth.setUTCMonth(startOfThisMonth.getUTCMonth() - 1);

        const endOfLastMonth = new Date(startOfThisMonth);
        endOfLastMonth.setUTCDate(0);
        endOfLastMonth.setUTCHours(23, 59, 59, 999);

        const startOfThisYear = new Date(now.getUTCFullYear(), 0, 1);
        const startOfLastYear = new Date(now.getUTCFullYear() - 1, 0, 1);
        const diffInDays = (now.getTime() - eventDate.getTime()) / (1000 * 60 * 60 * 24);
        const diffInWeeks = Math.floor(diffInDays / 7);
        const diffInMonths =
            (now.getUTCFullYear() - eventDate.getUTCFullYear()) * 12 +
            (now.getUTCMonth() - eventDate.getUTCMonth());
        const diffInYears = now.getUTCFullYear() - eventDate.getUTCFullYear();

        if (eventDate >= startOfYesterday && eventDate <= endOfYesterday) {
            return 'Recent';
        } else if (eventDate >= startOfThisWeek && eventDate < startOfYesterday) {
            return 'This week';
        } else if (eventDate >= startOfLastWeek && eventDate <= endOfLastWeek) {
            return 'Last week';
        } else if (diffInWeeks >= 2 && diffInWeeks <= 3) {
            return `${diffInWeeks} weeks ago`;
        } else if (eventDate >= startOfLastMonth && eventDate <= endOfLastMonth) {
            return 'Last month';
        } else if (diffInMonths >= 2 && diffInMonths <= 11) {
            return `${diffInMonths} months ago`;
        } else if (eventDate >= startOfLastYear && eventDate < startOfThisYear) {
            return 'Last year';
        } else {
            return `${diffInYears} years ago`;
        }
    }
}
