import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { SpinnerService } from 'app/spinner/spinner.service';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TransService } from '../translate/translate.service';
import { Util } from '../utils';
import { ApiService } from './api.service';
import { LanguageService } from './language.service';
import { SnackbarService } from './snack-bar.service';

@Injectable()
export class FirebaseSavePointService {
    user: any;
    org: any;
    language: string;
    userSavePointObj;
    constructor(
        public spinner: SpinnerService,
        public firebaseDb: AngularFireDatabase,
        public translate: TransService,
        public snackbarService: SnackbarService,
        public api: ApiService,
        public languageService: LanguageService,
    ) {}

    public getUserSavePoint(): Observable<any> {
        this.user = JSON.parse(localStorage.getItem('current_user'));
        if (this.user?.user_id) {
            return this.firebaseDb
                .list(`/savepoints/users/${this.user.user_id}`)
                .snapshotChanges()
                .pipe(
                    map((actions) => {
                        const data = [];
                        actions.forEach((action) => {
                            const obj: any = {};
                            obj.time = action.payload.val();
                            obj.key = action.key;
                            data.push(obj);
                        });
                        if (localStorage.getItem('userSavePoint')) {
                            if (
                                JSON.stringify(
                                    JSON.parse(localStorage.getItem('userSavePoint')) !==
                                        JSON.stringify(data),
                                )
                            ) {
                                const savedArray = JSON.parse(
                                    localStorage.getItem('userSavePoint'),
                                );
                                const newArray = data;
                                const updatedKey = newArray.reduce((keys, obj2) => {
                                    const updatedObj = savedArray.find(
                                        (obj) => obj.key === obj2.key,
                                    );
                                    if (updatedObj) {
                                        if (updatedObj.time !== obj2.time) {
                                            keys.push(obj2.key);
                                        }
                                    } else {
                                        keys.push(obj2.key);
                                    }
                                    return keys;
                                }, []);
                                if (updatedKey.length > 0) {
                                    switch (updatedKey[0]) {
                                        case 'report':
                                            this.snackbarService.success(
                                                'Your report has been generated',
                                            );
                                            break;
                                        case 'profile':
                                            this.api.refreshUserProfile();
                                            break;
                                        case 'import':
                                            this.snackbarService.success(
                                                'Your bulk import has been processed',
                                            );
                                            break;
                                        case 'genie-document':
                                            this.snackbarService.success(
                                                'Your Genie knowledge file request has been processed',
                                            );
                                            break;
                                        case 'craft-scenario':
                                            this.snackbarService.success(
                                                'Your craft request has been processed',
                                            );
                                            break;
                                        case 'craft-translate':
                                            this.snackbarService.success(
                                                'Your craft translation request has been processed',
                                            );
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                localStorage.setItem('userSavePoint', JSON.stringify(data));
                            }
                        } else {
                            localStorage.setItem('userSavePoint', JSON.stringify(data));
                        }
                        return data;
                    }),
                    catchError((error) => {
                        console.log(error);
                        return throwError(error);
                    }),
                );
        } else {
            return observableOf();
        }
    }

    public getOrgSavePoint(): Observable<any> {
        this.org = JSON.parse(localStorage.getItem('orgSetting'));
        if (this.org?.org_id) {
            return this.firebaseDb
                .list(`/savepoints/orgs/${this.org.org_id}`)
                .snapshotChanges()
                .pipe(
                    map((actions) => {
                        const data = [];
                        actions.forEach((action) => {
                            const obj: any = {};
                            obj.time = action.payload.val();
                            obj.key = action.key;
                            data.push(obj);
                        });
                        if (localStorage.getItem('orgSavePoint')) {
                            if (
                                JSON.stringify(
                                    JSON.parse(localStorage.getItem('orgSavePoint')) !==
                                        JSON.stringify(data),
                                )
                            ) {
                                const savedArray = JSON.parse(localStorage.getItem('orgSavePoint'));
                                const newArray = data;
                                const updatedKey = newArray.reduce((keys, obj2) => {
                                    const updatedObj = savedArray.find(
                                        (obj) => obj.key === obj2.key,
                                    );
                                    if (updatedObj) {
                                        if (updatedObj.time !== obj2.time) {
                                            keys.push(obj2.key);
                                        }
                                    } else {
                                        keys.push(obj2.key);
                                    }
                                    return keys;
                                }, []);
                                if (updatedKey.length > 0) {
                                    this.api.refreshOrgSetting();
                                }
                                localStorage.setItem('orgSavePoint', JSON.stringify(data));
                            }
                        } else {
                            localStorage.setItem('orgSavePoint', JSON.stringify(data));
                        }
                        return data;
                    }),
                    catchError((error) => {
                        console.log(error);
                        return throwError(error);
                    }),
                );
        } else {
            return observableOf();
        }
    }

    getUserInfo() {
        const tempUser = {
            org: this.user.org,
            type: this.user.type,
            principal: this.user.principal,
        };
        this.api.get('profile', { show_success: false, show_loader: true }).subscribe({
            next: (data: any) => {
                if (this.user && this.user['user_id'] === data['user_id']) {
                    this.user = data;
                    this.user.org = tempUser.org;
                    this.user.type = tempUser.type;
                    this.user.principal = tempUser.principal;
                    localStorage.setItem('current_user', JSON.stringify(this.user));
                    const lang = localStorage.getItem('translate_language');
                    this.translate.changeLang(lang);
                    if (lang !== data['language']) {
                        localStorage.setItem('translate_language', data['language']);
                        this.language = Util.filterArray(
                            this.languageService.getSupportedLanguage(),
                            'value',
                            data['language'],
                        ).viewValue;
                        this.translate.changeLang(data['language']);
                    }
                }
            },
            error: (err: any) => {
                if (err.status === 0) {
                    this.snackbarService.offline();
                }
            },
        });
    }
}
