import {
    AdminPermissionGuard,
    AuthGuard,
    LearnerPermissionGuard,
    MyGenieConfirmDeactivateGuard,
    QuizConfirmDeactivateGuard,
    PermissionGuard,
    SecurityGuard,
} from './shared/auth.guard';
import {
    AngularFireAnalyticsModule,
    CONFIG,
    UserTrackingService,
} from '@angular/fire/compat/analytics';
import {
    AngularFireRemoteConfig,
    AngularFireRemoteConfigModule,
    DEFAULTS as DEFAULT_CONFIG,
    SETTINGS as REMOTE_CONFIG_SETTINGS,
} from '@angular/fire/compat/remote-config';
import { ApiService, FormPermissionGuard, UpdateService, Util } from './shared';
import { ApplicationRef, NgModule, isDevMode } from '@angular/core';
import { BROWSER_FAVICONS_CONFIG, BrowserFavicons, Favicons } from './favicons';
import {
    BackWarningModalDirective,
    BackWarningService,
    ConfirmState,
} from './back-warning-modal/back-warning-modal.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AdminActiveTabService } from './shared/service/admin-active-tab.service';
import { SidebarService } from './shared/service/sidebar.service';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AppComponent } from './app.component';
import { AppHeaderService } from './shared/service/app-header.service';
import { AppRoutingModule } from './app.routing';
import { BackWarningComponent } from './back-warning-modal/back-warning-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmBoxComponent } from './confirmbox/confirmbox.component';
import { ConfirmBoxService } from './confirmbox/confirmbox.service';
import { DataService } from './shared/service/data.service';
import { DayService } from './shared/service/day.service';
import { DynamicScriptLoaderService } from './shared/service/dynamic-script-loader.service';
import { FAQService } from './shared/service/faq.service';
import { Four04Component } from './user_app/not_found/four04.component';
import { HttpCancelService } from './shared/service/http-cancel.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Interceptor } from 'app/shared/interceptor';
import { LanguageService } from './shared/service/language.service';
import { LogEventService } from './shared/service/LogEvent.service';
import { LoginUrlComponent } from './login-url/login-url.component';
import { MainPipe } from './shared/pipes/pipe.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MaterialModule } from './shared/material.module';
import { MessagingService } from './shared/service/messaging.service';
import { MobileNumberService } from './shared/service/mobile-number.service';
import { MobileUpdateModalComponent } from './mobile-update-modal/mobile-update-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationScenarioService } from './shared/service/notification-scenario.service';
import { NotificationService } from './shared/service/notification.service';
import { PermissionService } from './shared/service/permission.service';
import { PreLoaderComponent } from './user_app/pre-loader/preloader.component';
import { PreLoaderService } from './shared/service/preloader.service';
import { RemoteConfigService } from './shared/service/remote-config.service';
import { ReloadBoxComponent } from './reloadbox/reloadbox.component';
import { ReloadBoxService } from './reloadbox/reloadbox.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SessionTimeoutService } from './shared/service/session-timeout.service';
import { SessionTimerModalComponent } from './session-timer-modal/session-timer-modal.component';
import { SnackbarService } from './shared/service/snack-bar.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerService } from './spinner/spinner.service';
import { SubDomainService } from './shared/service/sub-domain.service';
import { SubscriptionUrlComponent } from './subscription-url/subscription-url.component';
import { TitleService } from './shared/service/title.service';
import { TransModule } from './shared/translate/translate.module';
import { TransService } from './shared/translate/translate.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UnderMaintenanceComponent } from './user_app/under_maintenance/under-maintenance.component';
import { UploadSpinnerComponent } from './upload-spinner/upload-spinner.component';
import { UploadSpinnerService } from './upload-spinner/upload-spinner.service';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from '../environments/environment';
import { ChunkInitializationService } from './chunk-initialization/chunk-initialization.service';
import { ChunkInitializationComponent } from './chunk-initialization/chunk-initialization.component';
import { MARKED_OPTIONS, MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { PushNotificationService } from './shared/service/push-notification-service';
import { FirebaseSavePointService } from './shared/service/firebase-save-point.service';
import { PreviousRouteService } from 'app/shared/service/previous-route.service';
import { AppHandleFilterParams } from './shared/service/app-handle-filter-params.service';
import { AuthGuardService } from './shared/service/auth-guard.service';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { marked } from 'marked';
import { CraftLanguageTranslationProgressService } from './shared/service/check-tranlation-inprogress.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/lang/', `.json?version=${Util.appVersion}`);
}

export function markedOptionsFactory(): MarkedOptions {
    const renderer: any = new MarkedRenderer();
    renderer.link = (linkObj) => {
        const parsedText = linkObj.title === null ? marked.parseInline(linkObj.text) : linkObj.text;
        return `<a href="${linkObj.href}" title="${linkObj.title || ''}" target="_blank" rel="noopener noreferrer">${parsedText}</a>`;
    };
    return { renderer };
}

@NgModule({
    imports: [
        AngularFireAnalyticsModule,
        AngularFireDatabaseModule,
        AngularFireMessagingModule,
        AngularFirePerformanceModule,
        AngularFireRemoteConfigModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        InfiniteScrollModule,
        MarkdownModule.forRoot({
            loader: HttpClient,
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory,
            },
        }),
        MainPipe,
        MaterialModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        TransModule.forRoot(),
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        UserIdleModule.forRoot({}),
    ],
    declarations: [
        AppComponent,
        BackWarningComponent,
        BackWarningModalDirective,
        ChunkInitializationComponent,
        ConfirmBoxComponent,
        Four04Component,
        LoginUrlComponent,
        MobileUpdateModalComponent,
        PreLoaderComponent,
        ReloadBoxComponent,
        SessionTimerModalComponent,
        SpinnerComponent,
        SubscriptionUrlComponent,
        UnderMaintenanceComponent,
        UploadSpinnerComponent,
    ],
    providers: [
        AdminActiveTabService,
        AdminPermissionGuard,
        LearnerPermissionGuard,
        ApiService,
        AppHeaderService,
        AppHandleFilterParams,
        MyGenieConfirmDeactivateGuard,
        AuthGuard,
        BackWarningService,
        ChunkInitializationService,
        ConfirmBoxService,
        ConfirmState,
        DataService,
        DayService,
        AuthGuardService,
        DynamicScriptLoaderService,
        FAQService,
        FirebaseSavePointService,
        FormPermissionGuard,
        HttpCancelService,
        LanguageService,
        LogEventService,
        MessagingService,
        MobileNumberService,
        NotificationScenarioService,
        NotificationService,
        PermissionGuard,
        PermissionService,
        PreLoaderService,
        PreviousRouteService,
        PushNotificationService,
        QuizConfirmDeactivateGuard,
        ReloadBoxService,
        RemoteConfigService,
        SecurityGuard,
        SessionTimeoutService,
        CraftLanguageTranslationProgressService,
        SidebarService,
        SnackbarService,
        SpinnerService,
        SubDomainService,
        Title,
        TitleService,
        TransService,
        UpdateService,
        UploadSpinnerService,
        UserTrackingService,
        provideShareButtonsOptions(shareIcons()),
        provideCharts(withDefaultRegisterables()),
        {
            provide: CONFIG,
            useValue: {
                send_page_view: false,
                allow_ad_personalization_signals: false,
                anonymize_ip: true,
            },
        },
        {
            provide: Favicons,
            useClass: BrowserFavicons,
        },
        {
            provide: BROWSER_FAVICONS_CONFIG,
            useValue: {
                icons: {
                    default: {
                        type: 'image/png',
                        href: 'assets/img/favicon.png?v=1',
                        isDefault: true,
                    },
                    notify: {
                        type: 'image/png',
                        href: 'assets/img/favicon_notify.png?v=1',
                    },
                },
            },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: DEFAULT_CONFIG,
            useValue: { secure_content_by_auth_header: false, org_cache_max_age_in_seconds: 86400 },
        },
        {
            provide: REMOTE_CONFIG_SETTINGS,
            useFactory: () =>
                isDevMode()
                    ? { fetchTimeoutMillis: 60000, minimumFetchIntervalMillis: 10_000 }
                    : { fetchTimeoutMillis: 60000 },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        public appRef: ApplicationRef,
        private remoteConfig: AngularFireRemoteConfig,
    ) {
        this.remoteConfig.fetchAndActivate();
    }
}
