import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CraftLanguageTranslationProgressService {
    private translationLanguagesSubject = new BehaviorSubject<boolean>(
        sessionStorage.getItem('translationLanguages') !== null,
    );

    constructor() {
        fromEvent<StorageEvent>(window, 'storage').subscribe(() => {
            this.updateTranslationState();
        });
    }

    checkTranslationStatus(): Observable<boolean> {
        return this.translationLanguagesSubject.asObservable();
    }

    updateTranslationState(): void {
        const exists = sessionStorage.getItem('translationLanguages') !== null;
        this.translationLanguagesSubject.next(exists);
    }
}
