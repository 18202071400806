export enum LogEventName {
    // Monitor Activation
    MONITOR_ACTIVATION_BY_GROUP_CLICKED = 'M101',
    MONITOR_ACTIVATION_BY_ROLE_CLICKED = 'M102',

    // Monitor Usage
    MONITOR_USAGE_BY_GROUP_CLICKED = 'M201',
    MONITOR_USAGE_BY_ROLE_CLICKED = 'M202',

    // Monitor LeaderBoard
    MONITOR_USER_LB_CLICKED = 'M301',
    MONITOR_TEAM_LB_CLICKED = 'M302',

    // Monitor LeaderBoard Responsive
    MONITOR_RESPONSIVE_USER_LB_CLICKED = 'M701',
    MONITOR_RESPONSIVE_TEAM_LB_CLICKED = 'M702',

    // Admin Mange Content
    QUIZ_SATUS_UPDATED_TO_LIVE = 'A401',
    NEW_FILE_UPLOADED_TO_LIBRARY = 'A402',
    LP_SATUS_UPDATED_TO_LIVE = 'A403',
    SURVEY_SATUS_UPDATED_TO_LIVE = 'A404',
    TEST_SATUS_UPDATED_TO_LIVE = 'A405',
    POLL_SATUS_UPDATED_TO_LIVE = 'A406',
    TEST_HOME_EDIT_BY_TITLE = 'A201',
    TEST_HOME_EDIT = 'A202',
    TEST_HOME_MANAGE_QUESTIONS = 'A203',
    TEST_HOME_MANAGE_FILE = 'A204',
    TEST_HOME_MANAGE_CERTIFICATE = 'A205',
    TEST_HOME_MAKE_LIVE = 'A206',
    TEST_HOME_ASSIGN = 'A207',
    TEST_DETAIL_EDIT = 'A208',
    TEST_DETAIL_MANAGE_QUESTIONS = 'A209',
    TEST_DETAIL_MANAGE_FILE = 'A210',
    TEST_DETAIL_MANAGE_CERTIFICATE = 'A211',
    TEST_DETAIL_MAKE_LIVE = 'A212',
    TEST_DETAIL_ASSIGN = 'A213',
    TEST_DETAIL_ASG_ROLE = 'A214',
    TEST_DETAIL_ASG_GROUP = 'A215',
    TEST_DETAIL_UNASG_GROUP = 'A216',
    TEST_DETAIL_UNASG_ROLE = 'A217',
    TEST_DETAIL_ASG_GROUP_HRY = 'A218',
    CRAFT_QUIZ_TRANSLATION = 'A1309',

    // Admin File

    FILE_HOME_EDIT = 'A2300',
    FILE_DETAIL_EDIT = 'A2301',
    FILE_DETAIL_DELETE = 'A2302',
    FILE_ADD_LANGUAGE = 'A2303',
    FILE_UPDATE_LANGUAGE = 'A2304',
    FILE_DELETE_LANGUAGE = 'A2305',
    FILE_ADD_THUMBNAIL = 'A2306',
    FILE_REMOVE_THUMBNAIL = 'A2307',
    FILE_HOME_GROUP = 'A1101',
    FILE_HOME_ROLE = 'A1102',
    FILE_HOME_PATH = 'A1103',
    FILE_DETAIL_INFO = 'A1104',
    FILE_DETAIL_LANGUAGE = 'A1105',
    FILE_DETAIL_ASSIGN = 'A1106',
    FILE_DETAIL_UNASG_ROLE = 'A1107',
    FILE_DETAIL_ASG_ROLE = 'A1108',
    FILE_DETAIL_UNASG_GROUP = 'A1109',
    FILE_DETAIL_ASG_GROUP = 'A1110',
    FILE_DETAIL_ASG_GROUP_HRY = 'A1111',
    EXPLORER_TOGGLE_BUTTON = 'L1419',
    FILE_LIST_TOGGLE_BUTTON = 'L1420',
    EXPLORER_ITEM_CLICKED = 'L1421',

    // Admin Quiz

    QUIZ_HOME_EDIT_BY_TITLE = 'A2100',
    QUIZ_HOME_EDIT = 'A2101',
    QUIZ_HOME_MANAGE_QUESTIONS = 'A2102',
    QUIZ_HOME_MANAGE_FILE = 'A2103',
    QUIZ_HOME_MANAGE_CERTIFICATE = 'A2104',
    QUIZ_HOME_MAKE_LIVE = 'A2105',
    QUIZ_HOME_ASSIGN = 'A2106',
    QUIZ_DETAIL_EDIT = 'A2107',
    QUIZ_DETAIL_MANAGE_QUESTIONS = 'A2108',
    QUIZ_DETAIL_MANAGE_FILE = 'A2109',
    QUIZ_DETAIL_MANAGE_CERTIFICATE = 'A2110',
    QUIZ_DETAIL_MAKE_LIVE = 'A2111',
    QUIZ_DETAIL_ASSIGN = 'A2112',
    QUIZ_DETAIL_ASG_ROLE = 'A2113',
    QUIZ_DETAIL_ASG_GROUP = 'A2114',
    QUIZ_DETAIL_UNASG_GROUP = 'A2115',
    QUIZ_DETAIL_UNASG_ROLE = 'A2116',
    QUIZ_DETAIL_ASG_GROUP_HRY = 'A2117',

    // Admin Survey
    SURVEY_HOME_EDIT_BY_TITLE = 'A2000',
    SURVEY_HOME_EDIT = 'A2001',
    SURVEY_HOME_MANAGE_QUESTIONS = 'A2002',
    SURVEY_HOME_MAKE_LIVE = 'A2003',
    SURVEY_HOME_ASSIGN = 'A2004',
    SURVEY_DETAIL_INFO = 'A2005',
    SURVEY_DETAIL_MANAGE_QUESTIONS = 'A2006',
    SURVEY_DETAIL_MAKE_LIVE = 'A2007',
    SURVEY_DETAIL_ASSIGN = 'A2008',
    SURVEY_DETAIL_ASG_ROLE = 'A2009',
    SURVEY_DETAIL_ASG_GROUP = 'A2010',
    SURVEY_DETAIL_UNASG_GROUP = 'A2011',
    SURVEY_DETAIL_UNASG_ROLE = 'A2012',
    SURVEY_DETAIL_ASG_GROUP_HRY = 'A2013',

    // Admin Poll
    POLL_HOME_EDIT_BY_TITLE = 'A300',
    POLL_HOME_EDIT = 'A301',
    POLL_HOME_MAKE_LIVE = 'A302',
    POLL_HOME_ASSIGN_GROUP_ROLE = 'A303',
    POLL_DETAIL_INFO = 'A304',
    POLL_DETAIL_ASSIGN = 'A305',
    POLL_DETAIL_MAKE_LIVE = 'A306',
    POLL_DETAIL_DELETE = 'A307',
    POLL_DETAIL_INACTIVE = 'A308',
    POLL_DETAIL_ASG_ROLE = 'A309',
    POLL_DETAIL_ASG_GROUP = 'A3010',
    POLL_DETAIL_UNASG_GROUP = 'A311',
    POLL_DETAIL_UNASG_ROLE = 'A312',
    POLL_DETAIL_ASG_GROUP_HRY = 'A313',

    // Admin Assignment
    ASSIGNMENT_HOME_EDIT_BY_TITLE = 'A500',
    ASSIGNMENT_HOME_EDIT = 'A501',
    ASSIGNMENT_HOME_MAKE_LIVE = 'A502',
    ASSIGNMENT_HOME_ASSIGN_GROUP_ROLE = 'A503',
    ASSIGNMENT_HOME_SUBMISSIONS = 'A504',
    ASSIGNMENT_DETAIL_INFO = 'A505',
    ASSIGNMENT_DETAIL_ASSIGN = 'A506',
    ASSIGNMENT_DETAIL_SUBMISSIONS = 'A507',
    ASSIGNMENT_DETAIL_MAKE_LIVE = 'A508',
    ASSIGNMENT_DETAIL_DELETE = 'A509',
    ASSIGNMENT_DETAIL_INACTIVE = 'A510',
    ASSIGNMENT_DETAIL_ASG_ROLE = 'A511',
    ASSIGNMENT_DETAIL_ASG_GROUP = 'A512',
    ASSIGNMENT_DETAIL_UNASG_GROUP = 'A513',
    ASSIGNMENT_DETAIL_UNASG_ROLE = 'A514',
    ASSIGNMENTL_DETAIL_ASG_GROUP_HRY = 'A515',

    // Admin Leaderboard
    LB_HOME_EDIT_BY_TITLE = 'A2200',
    LB_HOME_EDIT = 'A2201',
    LB_HOME_SCHEDULE_NOW = 'A2202',
    LB_HOME_SCHEDULE = 'A2203',
    LB_HOME_RELEASE = 'A2204',
    LB_HOME_ENABLE = 'A2205',
    LB_DETAIL_INFO = 'A2206',
    LB_DETAIL_ASSIGN = 'A2207',
    LB_DETAIL_RELEASE = 'A2208',
    LB_DETAIL_SCHEDULE_NOW = 'A2209',
    LB_DETAIL_SCHEDULE = 'A2210',
    LB_DETAIL_DELETE = 'A2211',
    LB_DETAIL_DISABLE = 'A2212',
    LB_DETAIL_ENABLE = 'A2213',
    LB_DETAIL_ASG_ROLE = 'A2214',
    LB_DETAIL_ASG_GROUP = 'A2215',
    LB_MULTIPLE_GROUP_ASG = 'A2216',
    LB_MULTIPLE_ROLE_ASG = 'A2217',
    LB_DETAIL_UNASG_GROUP = 'A2218',
    LB_DETAIL_UNASG_ROLE = 'A2219',

    // Take Quiz
    APP_BAR_CLICKED = 'L1000',
    TAKE_QUIZ_CLICKED = 'L1001',
    QUIZ_DETAILS_CLICKED = 'L1002',
    QUIZ_HELP_CLICKED = 'L1003',
    QUIZ_LIBRARY_CLICKED = 'L1004',
    QUIZ_NEW_SCENARIO_NOTIFICATION_CLICKED = 'L1013',
    QUIZ_RED_ALERT_NOTIFICATION_CLICKED = 'L1014',
    COMPLETED_QUIZ_TAB_CLICKED = 'L1015',
    COMPLETED_QUIZ_REVIEW_SCENARIO_CLICKED = 'L1016',
    COMPLETED_QUIZ_BOOKMARK_CLICKED = 'L1017',
    BOOKMARKED_QUIZ_TAB_CLICKED = 'L1018',
    BOOKMARKED_QUIZ_SCENARIO_REVIEW_CLICKED = 'L1019',
    BOOKMARKED_QUIZ_SCENARIO_UNBOOKMARK_CLICKED = 'L1020',
    QUESTION_BOOKMARKED_DURING_MASTERY = 'L1021',
    SWITCH_QUESTION_LANGUAGES = 'L1023',
    HOMEPAGE_QUIZ_CERTIFICATE_INFO = 'L6003',
    QUIZ_VIEW_CERTIFICATE_CLICKED = 'L6012',
    QUIZ_DOWNLOAD_CERTIFICATE_CLICKED = 'L6013',

    // Take Test
    TAKE_TEST_CLICKED = 'L1201',
    REVIEW_TEST_CLICKED = 'L1202',
    SUBMIT_TEST_CLICKED = 'L1203',
    TEST_VIEW_RESPONSE_CLICKED = 'L1204',
    TEST_VIEW_ANSWER_CLICKED = 'L1205',
    COMPLETED_TEST_TAB_CLICKED = 'L1206',
    TAKE_TEST_LIBRARY_CLICKED = 'L1207',
    HOMEPAGE_TEST_CERTIFICATE_INFO = 'L6004',
    TEST_RESULT_CERTIFICATE_CLICKED = 'L6009',

    // Take Poll
    TAKE_OPINION_CLICKED = 'L1301',
    OPINION_HELP_CLICKED = 'L1302',
    COMPLETED_OPINION_TAB_CLICKED = 'L1303',
    COMPLETED_OPINION_REVIEW_CLICKED = 'L1304',

    // Library
    LIBRARY_TAB_CLICKED = 'L1401',
    LIBRARY_BOOKMARK_CLICKED = 'L1402',
    LIBRARY_DOWNLOAD_CLICKED = 'L1403',

    NOTIFICATION_TAB_CLICKED = 'L1502',

    // My-score LeaderBoard
    USER_LB_WINNER_NOTIFICATION_CLICKED = 'L1601',
    APP_BAR_LB_CLICKED = 'L1602',
    TEAM_LB_WINNER_NOTIFICATION_CLICKED = 'L1603',
    USER_LB_CLICKED = 'L1604',
    LB_COLLAPSE_CLICKED = 'L1605',
    USER_LB_CANDIDATE_DETAILS_CLICKED = 'L1606',
    LB_EXPANDED = 'L1607',
    LB_COLLAPSED = 'L1608',
    USER_LB_DETAILS_CLICKED = 'L1609',
    TEAM_LB_CLICKED = 'L1610',
    TEAM_LB_DETAILS_CLICKED = 'L1611',
    TEAM_LB_TEAM_DRILL_DOWN_CLICKED = 'L1612',
    TEAM_LB_CANDIDATE_DETAILS_CLICKED = 'L1613',

    // My-score Badge
    BADGE_NEW_RELEASE_NOTIFICATION_CLICKED = 'L1701',
    BADGES_CLICKED = 'L1702',
    BADGE_DETAILS_CLICKED = 'L1703',
    NEW_BADGE_DETAILS_CLICKED = 'L1704',

    // //My-profile emailand mobile verification
    VERIFY_EMAIL_CLICKED = 'L1801',
    VERIFY_MOBILE_CLICKED = 'L1802',

    //Home FAQ
    FAQ_HOW_TO_RAPL = 'L1903',
    FAQ_HOW_TO_EARN_POINTS = 'L1904',
    FAQ_HOW_TO_EARN_BADGES = 'L1905',
    FAQ_HOW_TO_ACHIEVE_MASTERY = 'L1906',
    FAQ_HOW_TO_CLIMB_LB = 'L1907',
    FAQ_HOW_TO_EARN_CERTIFICATE = 'L1908',
    FAQ_HOW_TO_PAUSE_LEARNING = 'L1909',
    VIEW_ALL_FAQ = 'L1910',

    // Report an issue write to us
    FAQ_VOTE_DOWN_CLICKED = 'L1902',

    // Learner Genie
    GENIE_RESPONSE_UP_VOTE_BUTTON_CLICKED = '50005',
    GENIE_RESPONSE_DOWN_VOTE_BUTTON_CLICKED = '50006',
    GENIE_RESPONSE_COPY_BUTTON_CLICKED = '50007',
    GENIE_SUBMIT_FEEDBACK_BUTTON_CLCIKED = '50008',
    GENIE_RESPONSE_VIEW_SOURCE_CLICKED = '50009',
    GENIE_SESSIONS = '50010',
    GENIE_SESSION_TITLE_RENAME = '50011',
    GENIE_SESSION_DELETE = '50012',
    GENIE_SESSION_VIEW = '50013',
    GENIE_SUGGESTION_CLICKED = '50014',

    // Learner Events
    EVENT_HOME_VIEWALL = 'L1100',
    EVENT_HOME_JOIN = 'L1101',
    EVENT_HOME_INFO = 'L1102',
    EVENT_LIST_ITEM_INFO = 'L1103',
    EVENT_LIST_ITEM_JOIN = 'L1104',
    EVENT_LIST_HISTORY = 'L1105',
    EVENT_LIST_HISTORY_INFO = 'L1106',
    EVENT_INFO_JOIN = 'L1107',
    EVENT_CLICKED = 'L1108',
    EVENT_ATTACHMENT = 'L1109',
    EVENT_RECORDING = 'L1110',

    // App update modal
    UPDATE_APP_CLICKED = 'L2003',
    UPDATE_APP_POSTPONED = 'L2004',
    WEB_APP_SHOW_UPDATE_POPUP = 'L2005',
    WEB_APP_FORCE_UPDATE = 'L2006',

    // My account and Logout
    MY_ACCOUNT_TAB_CLICKED = 'L2201',

    // History and Bookmark
    BOOKMARK_TAB_CLICKED = 'L3002',

    REQUEST_TIMED_OUT = 'R9999',
    VIEW_HISTORY_QUIZ_CERTIFICATE = 'L6005',
    VIEW_HISTORY_TEST_CERTIFICATE = 'L6006',

    // Assignment
    TAKE_ASSIGNMENT_CLICKED = 'L4001',
    SAVE_ASSIGNMENT_CLICKED = 'L4002',
    SUBMIT_ASSIGNMENT_CLICKED = 'L4003',
    COMPLETED_ASSIGNMENT_TAB_CLICKED = 'L4004',
    COMPLETED_ASSIGNMENT_REVIEW = 'L4005',

    // Subscription
    ADD_SUBSCRIPTION = 'L5002',
    UNSUBSCRIBE = 'L5003',
    SUBSCRIBE = 'L5004',
    DELETE = 'L5005',
    SUBSCRIBE_NOW = 'L5006',
    AUTO_SUBSCRIPTION_SUCCESS = 'L5007',
    AUTO_SUBSCRIPTION_ERROR = 'L5008',

    //Manage report
    NOT_ACTIVATED_USERS_BY_ORG = 'A407',
    ACTIVATION_STATUS_BY_GROUP = 'A408',
    NOT_ACTIVATED_USERS_BY_GROUP = 'A409',
    USAGE_STATUS_BY_GROUP = 'A410',
    NOT_USING_USERS_BY_GROUP = 'A411',
    CREDIT_REPORT_BY_GROUP = 'A412',
    USER_WISE_TOPIC_STATS_BY_GROUP = 'A413',
    TEST_RESPONSE_BY_GROUP = 'A414',
    TOPIC_WISE_COMPLETION = 'A415',
    USER_WISE_COMPLETION = 'A416',
    ACTIVATION_STATUS_BY_ROLE = 'A417',
    NOT_ACTIVATED_USERS_BY_ROLE = 'A418',
    USAGE_STATUS_BY_ROLE = 'A419',
    NOT_USING_USERS_BY_ROLE = 'A420',
    CREDIT_REPORT_BY_ROLE = 'A421',
    USER_WISE_TOPIC_STATS_BY_ROLE = 'A422',
    TEST_RESPONSE_BY_ROLE = 'A423',
    TOPIC_WISE_LEARNING_SPEED = 'A424',
    USER_TEST_SUMMARY_BY_GROUP = 'A425',
    CREDIT_REPORT_BY_TOPIC = 'A426',
    USER_FILE_VIEW_STATS_BY_GROUP = 'A427',
    USER_TEST_SUMMARY_BY_ROLE = 'A428',
    USER_FILE_VIEW_STATS_BY_ROLE = 'A429',
    LP_USER_WISE_SUMMARY_BY_GROUP = 'A430',
    LP_TOPIC_SUMMARY_BY_GROUP = 'A431',
    LP_USER_WISE_SUMMARY_BY_ROLE = 'A432',
    LP_USER_TOPIC_WISE_SUMMARY_BY_ROLE = 'A433',
    REPORT_DOWNLOAD_BOOKMARK_TEMPLATE = 'A440',
    REPORT_DOWNLOAD_HOME_LEARN = 'A441',
    REPORT_DOWNLOAD_HOME_ENGAGEMENT = 'A442',
    REPORT_DOWNLOAD_HOME_SOCIAL = 'A443',
    REPORT_DOWNLOAD_HOME_FEEDBACK = 'A444',
    REPORT_DOWNLOAD_HOME_ADMIN = 'A445',
    REPORT_DOWNLOAD_SELECT_TEMPLATE = 'A446',
    REPORT_DOWNLOAD_BOOKMARK_MORE = 'A447',
    REPORT_SCHEDULE_BOOKMARK_MORE = 'A448',
    REPORT_SCHEDULE_HOME_LEARN = 'A450',
    REPORT_SCHEDULE_HOME__ENGAGEMENT = 'A451',
    REPORT_SCHEDULE_HOME_SOCIAL = 'A452',
    REPORT_SCHEDULE_HOME_FEEDBACK = 'A453',
    REPORT_SCHEDULE_HOME_ADMIN = 'A454',
    REPORT_SCHEDULE_SELECT_TEMPLATE = 'A449',
    REPORT_SCHEDULE_SELECT_SEARCH_TEMPLATE = 'A457',
    REPORT_SCHEDULE_SELECT_BOOKMARK_TEMPLATE = 'A455',
    REPORT_DOWNLOAD_SELECT_BOOKMARK_TEMPLATE = 'A456',
    REPORT_DOWNLOAD_SELECT_SEARCH_TEMPLATE = 'A458',
    REPORT_SCHEDULE_BOOKMARK_TEMPLATE = 'A459',
    REPORT_SCHEDULE_UNBOOKMARK_TEMPLATE = 'A460',
    REPORT_DOWNLOAD_UNBOOKMARK_TEMPLATE = 'A461',
    REPORT_CREATE_DOWNLOAD = 'A462',
    REPORT_CANCEL_CREATE_DOWNLOAD = 'A463',
    REPORT_CREATE_SCHEDULE = 'A464',
    CANCEL_CREATE_SCHEDULE_REPORT = 'A465',
    REPORT_ADMIN_DOWNLOAD = 'A466',
    REPORT_ADMIN_SCHEDULE = 'A467',

    //Monitor
    MONITOR_USAGE_BY_GROUP_QUIZ = 'M205',
    MONITOR_USAGE_BY_GROUP_TEST = 'M206',
    MONITOR_USAGE_BY_GROUP_POLL = 'M207',
    MONITOR_USAGE_BY_GROUP_SURVEY = 'M208',
    MONITOR_USAGE_BY_GROUP_LIBRARY = 'M209',
    MONITOR_USAGE_BY_GROUP_ASSIGNMENT = 'M210',
    MONITOR_USAGE_BY_ROLE_QUIZ = 'M211',
    MONITOR_USAGE_BY_ROLE_TEST = 'M212',
    MONITOR_USAGE_BY_ROLE_POLL = 'M213',
    MONITOR_USAGE_BY_ROLE_SURVEY = 'M214',
    MONITOR_USAGE_BY_ROLE_LIBRARY = 'M215',
    MONITOR_USAGE_BY_ROLE_ASSIGNMENT = 'M216',
    MONITOR_COMPLETION_QUIZ = 'M217',
    MONITOR_COMPLETION_TEST = 'M218',
    MONITOR_COMPLETION_POLL = 'M219',
    MONITOR_COMPLETION_SURVEY = 'M220',
    MONITOR_COMPLETION_LIBRARY = 'M221',
    MONITOR_COMPLETION_LP = 'M222',
    MONITOR_COMPLETION_ASSINGMENT = 'M223',
    MONITOR_ACTIVATION_BY_GROUP_ROLE = 'M224',

    //Monitor completion group
    MONITOR_COMPLETION_BY_GROUP_QUIZ = 'M601',
    MONITOR_COMPLETION_BY_GROUP_TEST = 'M602',
    MONITOR_COMPLETION_BY_GROUP_POLL = 'M603',
    MONITOR_COMPLETION_BY_GROUP_SURVEY = 'M604',
    MONITOR_COMPLETION_BY_GROUP_LIBRARY = 'M605',
    MONITOR_COMPLETION_BY_GROUP_ASSIGNMENT = 'M606',
    MONITOR_COMPLETION_BY_GROUP_FEEDBACK = 'M607',
    MONITOR_COMPLETION_BY_GROUP_LP = 'M608',
    MONITOR_COMPLETION_SUBGROUP = 'M609',
    MONITOR_COMPLETION_BY_GROUP_QUIZ_DETAILS = 'M610',
    MONITOR_COMPLETION_BY_GROUP_TEST_DETAILS = 'M611',
    MONITOR_COMPLETION_BY_GROUP_POLL_DETAILS = 'M612',
    MONITOR_COMPLETION_BY_GROUP_SURVEY_DETAILS = 'M613',
    MONITOR_COMPLETION_BY_GROUP_LIBRARY_DETAILS = 'M614',
    MONITOR_COMPLETION_BY_GROUP_ASSIGNMENT_DETAILS = 'M615',
    MONITOR_COMPLETION_BY_GROUP_FEEDBACK_DETAILS = 'M616',
    MONITOR_COMPLETION_BY_GROUP_LP_DETAILS = 'M617',
    MONITOR_COMPLETION_BY_GROUP_QUIZ_USERS = 'M618',
    MONITOR_COMPLETION_BY_GROUP_TEST_USERS = 'M619',
    MONITOR_COMPLETION_BY_GROUP_POLL_USERS = 'M620',
    MONITOR_COMPLETION_BY_GROUP_SURVEY_USERS = 'M621',
    MONITOR_COMPLETION_BY_GROUP_LIBRARY_USERS = 'M622',
    MONITOR_COMPLETION_BY_GROUP_ASSIGNMENT_USERS = 'M623',
    MONITOR_COMPLETION_BY_GROUP_LP_USERS = 'M624',

    //Monitor completion role
    MONITOR_COMPLETION_BY_ROLE_QUIZ = 'M625',
    MONITOR_COMPLETION_BY_ROLE_TEST = 'M626',
    MONITOR_COMPLETION_BY_ROLE_POLL = 'M627',
    MONITOR_COMPLETION_BY_ROLE_SURVEY = 'M628',
    MONITOR_COMPLETION_BY_ROLE_LIBRARY = 'M629',
    MONITOR_COMPLETION_BY_ROLE_ASSIGNMENT = 'M630',
    MONITOR_COMPLETION_BY_ROLE_FEEDBACK = 'M631',
    MONITOR_COMPLETION_BY_ROLE_LP = 'M632',
    MONITOR_COMPLETION_BY_ROLE_QUIZ_DETAILS = 'M633',
    MONITOR_COMPLETION_BY_ROLE_TEST_DETAILS = 'M634',
    MONITOR_COMPLETION_BY_ROLE_POLL_DETAILS = 'M635',
    MONITOR_COMPLETION_BY_ROLE_SURVEY_DETAILS = 'M636',
    MONITOR_COMPLETION_BY_ROLE_LIBRARY_DETAILS = 'M637',
    MONITOR_COMPLETION_BY_ROLE_ASSIGNMENT_DETAILS = 'M638',
    MONITOR_COMPLETION_BY_ROLE_LP_DETAILS = 'M640',
    MONITOR_COMPLETION_BY_ROLE_QUIZ_USERS = 'M641',
    MONITOR_COMPLETION_BY_ROLE_TEST_USERS = 'M642',
    MONITOR_COMPLETION_BY_ROLE_POLL_USERS = 'M643',
    MONITOR_COMPLETION_BY_ROLE_SURVEY_USERS = 'M644',
    MONITOR_COMPLETION_BY_ROLE_LIBRARY_USERS = 'M645',
    MONITOR_COMPLETION_BY_ROLE_ASSIGNMENT_USERS = 'M646',
    MONITOR_COMPLETION_BY_ROLE_LP_USERS = 'M647',

    //Monitor completion
    MONITOR_COMPLETION_BY_CONTENT = 'M650',
    MONITOR_COMPLETION_BY_CONTENT_FILE_REACTIONS = 'M233',
    MONITOR_COMPLETION_BY_USER = 'M651',
    MONITOR_COMPLETION_BY_GROUP = 'M652',
    MONITOR_COMPLETION_BY_ROLE = 'M653',
    MONITOR_COMPLETION_BY_GROUP_USERS = 'M654',
    MONITOR_COMPLETION_BY_ROLE_USERS = 'M655',

    //Admin certificates
    ADMIN_CERTIFIACTE_TEMPLATE = 'A601',
    ADMIN_QUIZ_MANAGE_CERTIFICATE = 'A602',
    ADMIN_QUIZ_ISSUED_CERTIFICATES = 'A603',
    ADMIN_TEST_MANAGE_CERTIFICATE = 'A604',
    ADMIN_TEST_ISSUED_CERTIFICATES = 'A605',

    //Admin - Feedback template - Import question
    FEEDBACK_IMPORT_QUESTION = 'A434',

    //Learner certificate
    VIEW_CERTIFICATE = 'L6002',

    //Onboard users
    CREATE_UPDATE_USER = 'A606',
    CREATE_UPDATE_INVITE_USER = 'A607',

    //AuditLog
    AUDIT_LOG = 'A608',

    //Admin-Manage users - copy to clipboard
    COPY_TO_CLIPBOARD = 'A609',

    //Admin Pause Learning
    CREATE_PAUSE_LEARNING = 'A435',
    EDIT_PAUSE_LEARNING = 'A436',
    CANCEL_PAUSE_LEARNING = 'A437',

    //Admin craft
    CRAFT_UNDER_QUIZ = 'A1300',
    CRAFT_ADD_LANGUAGE = 'A1301',
    CRAFT_REMOVE_LANGUAGE = 'A1302',
    CRAFT_CREATE_QUIZ = 'A1303',
    CRAFT_CREATE_FILE = 'A1304',
    CRAFT_UNDER_TEST = 'A1305',
    CRAFT_TEST_ADD_LANGUAGE = 'A1306',
    CRAFT_TEST_REMOVE_LANGUAGE = 'A1307',
    CRAFT_CREATE_TEST = 'A1308',

    //ULP
    ADD_PATH_ITEM = 'A501',
    INSERT_PATH_ITEM = 'A502',
    DELETE_PATH_ITEM = 'A503',
    UPDATE_PATH_ITEM = 'A504',

    //Learner Inactivate Account
    INACTIVATE_ACC = 'A438',

    //Update orgsettings
    ORG_SETTINGS = 'A439',

    //Library Reactions
    ADD_REACTION = 'L8001',
    DELETE_REACTION = 'L8002',
    ADD_COMMENT = 'L8003',
    EDIT_COMMENT = 'L8004',
    OPEN_MORE_REACTIONS = 'L8005',

    // Monitor activation and usage
    MONITOR_USAGE_BY_USER_CLICKED = 'M105',
    MONITOR_ACTIVATION_BY_USER_CLICKED = 'M106',
    USAGE_QUESTIONS_ATTEMPTED_USER_COUNT_CLICKED = 'M225',
    USAGE_NO_QUESTIONS_AVAILABLE_USER_COUNT_CLICKED = 'M226',
    USAGE_NO_QUESTIONS_ATTEMPTED_USER_COUNT_CLICKED = 'M227',
    ACTIVATION_ACTIVATED_USER_COUNT_CLICKED = 'M228',
    ACTIVATION_NOT_ACTIVATED_USER_COUNT_CLICKED = 'M229',
    ACTIVATION_USERS_EXPORT = 'M231',
    ACTIVATION_PRIVILEGED_USERS_EXPORT = 'M232',

    // Library filter
    LIBRARY_FILTER_BY_CATEGORY_CLICKED = 'L1406',
    LIBRARY_FILTER_BY_TAG_CLICKED = 'L1407',
    LIBRARY_FILTER_BY_TYPE_CLICKED = 'L1408',
    LIBRARY_FILTER_BY_TOPIC_CLICKED = 'L1409',
    LIBRARY_FILTER_BY_TEST_CLICKED = 'L1410',
    LIBRARY_SORT_BY_CLICKED = 'L1411',
    LIBRARY_FILTER_BY_VIEWED_CLICKED = 'L1412',
    LIBRARY_VIEW_FILTER = 'L1414',

    //LEARNER QUIZ SWITCH LANGUAGE
    AVAILABLE_LANGUAGE_SELECTED = 'L1025',
    UNAVAILABLE_LANGUAGE_SELECTED = 'L1026',
    SWITCH_LANGUAGE_BUTTON_CLICKED = 'L1024',

    //LEARNER INSIGHT
    LEARNER_INSIGHT_TAB_CLICKED = 'L9001',
    INSIGHT_CERTIFICATE_VIEW_ALL = 'L6009',
    LEARNER_INSIGHT_MSG_BOX_CLICKED = 'L9002',
    LEARNER_INSIGHT_PERFORMANCE_TAB_CLICKED = 'L9003',
    LEARNER_INSIGHT_PROGRESS_TAB_CLICKED = 'L9004',
    LEARNER_INSIGHT_TOPIC_PERFORMANCE_HELP_CLICKED = 'L9005',
    LEARNER_INSIGHT_TOPIC_PROGRESS_VIEWMORE_CLICKED = 'L9006',
    LEARNER_INSIGHT_TEST_PROGRESS_VIEWMORE_CLICKED = 'L9007',
    LEARNER_INSIGHT_BADGE_INFO = 'L9008',
    LEARNER_INSIGHT_POINTS_INFO = 'L9009',
    LEARNER_INSIGHT_BADGES_CHANGE_PERIOD = 'L9010',
    LEARNER_INSIGHT_POINTS_CHANGE_PERIOD = 'L9011',

    //Email Template
    EMAIL_TEMPLATE = 'A701',

    //Learner Path
    VIEW_PATH_CERTIFICATE = 'L10004',
    VIEW_LEARNING_PATH_ITEMS = 'L10001',
    VIEW_ALL_PATH = 'L10005',
    PATH_HOME_TAKE_QUIZ = 'L10006',
    PATH_HOME_ASSESSMENT = 'L10007',
    PATH_HOME_ASSIGNMENT = 'L10008',
    PATH_HOME_SURVEY = 'L10009',
    PATH_HOME_POLL = 'L10010',
    PATH_HOME_LIBRARY = 'L10011',
    PATH_DETAIL_TAKE_QUIZ = 'L10012',
    PATH_DETAIL_ASSESSMENT = 'L10013',
    PATH_DETAIL_ASSIGNMENT = 'L10014',
    PATH_DETAIL_SURVEY = 'L10015',
    PATH_DETAIL_POLL = 'L10016',
    PATH_DETAIL_LIBRARY = 'L10017',
    PATH_DETAIL_REVIEW = 'L10018',
    PATH_DETAIL_CERTIFICATE_CLICKED = 'L10019',
    COMPLETED_PATH_CLICKED = 'L10020',
    HISTORY_PATH_REVIEW = 'L10021',
    HISTORY_PATH_DETAIL_REVIEW = 'L10022',
    HISTORY_PATH_CERTIFICATE = 'L10023',
    HISTORY_PATH_DETAIL_CERTIFICATE = 'L10024',

    //ISSUE_TEMPLATE
    ISSUE_TEMPLATE = 'A801',
    CREATE_ISSUE_TEMPLATE = 'A802',
    TOGGLE_ISSUE_TEMPLATE_STATUS = 'A803',

    //LEARNER MYACCOUNT
    MY_SUBSCRIPTION = 'L5001',
    MY_VACATION = 'L7001',
    MY_LEARNING = 'L3001',
    TROUBLESHOOT_NOTIFICATION = 'L2104',
    ABOUT_RAPL = 'L2001',
    LOGOUT_REQUESTED = 'L2202',
    MY_ACCOUNT_FROM_MENU = 'L3003',
    FEEDBACK_FROM_MENU = 'L3004',
    SUB_MENU_INSIDE_ACCOUNT_PROFILE = 'L3005',
    HELP_IN_ACCOUNT_PROFILE = 'L3006',
    MENU_HOME = 'L3007',

    //Dashboard privileged users
    MONITOR_DASHBOARD_PRIVILEGED_USERS_CLICKED = 'M230',

    //Admin path content preview
    ADMIN_PATH_CONTENT_PREVIEW = 'A901',

    //Admin schedule invite
    ADMIN_SCHEDULE_INVITE = 'A1001',
    ADD_SCHEDULE_INVITE = 'A1002',
    MAKE_SCHEDULE_INVITE_LIVE = 'A1003',

    //LEARNER FORMS
    FORM_CLICKED = 'L10100',
    TODO_FORMS_TAB_CLICKED = 'L10101',
    TODO_SCHEDULE_FORM_FILL_BUTTON_CLICKED = 'L10102',
    TODO_CATALOGUE_FORM_FILL_BUTTON_CLICKED = 'L10103',
    FORM_SUBMISSIONS_BUTTON_CLICKED = 'L10104',
    SUBMISSIONS_FORM_REVIEW_CLICKED = 'L10105',
    CATALOGUE_FORMS_BUTTON_CLICKED = 'L10106',
    CATALOGUE_FORM_FILL_BUTTON_CLICKED = 'L10107',
    TEAM_TODO_FORMS_TAB_CLICKED = 'L10108',
    TEAM_TODO_FORM_FILL_BUTTON_CLICKED = 'L10109',
    TEAM_FORM_SUBMISSIONS_BUTTON_CLICKED = 'L10110',
    TEAM_SUBMISSIONS_FORM_REVIEW_CLICKED = 'L10111',
    FORM_SUBMISSIONS_SORT_BUTTON_CLICKED = 'L10112',
    INDIVIDUAL_FORM_SUBMISSIONS_OPTION_CLICKED = 'L10113',
    INDIVIDUAL_SUBMISSIONS_FORM_REVIEW_CLICKED = 'L10114',
    DELETE_FORM_OPTION_CLICKED = 'L10115',

    //Admin forms
    FORM_FIELD_PREV_NEXT = 'A1001',

    //Monitor user routine
    VIEW_USER_ROUTINE = 'M401',
    DOWNLOAD_USER_ROUTINE_CHART = 'M402',
    TOGGLE_USER_ROUTINE_CHART = 'M403',
    EXPORT_USER_ROUTINE_CHART = 'M404',
    EXPORT_USER_ROUTINE_LIST_CHART = 'M405',

    //Form analytics
    FORM_USER_ENTRIES_EXPORT = 'M501',
    FORM_USER_RESPONSES_EXPORT = 'M502',
    DOWNLOAD_USER_ENTRIES_CHART = 'M503',
}
