import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReloadBoxService } from 'app/reloadbox/reloadbox.service';
import { DataService } from 'app/shared/service/data.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { PermissionService } from 'app/shared/service/permission.service';
import { SnackbarService } from 'app/shared/service/snack-bar.service';
import { SubDomainService } from 'app/shared/service/sub-domain.service';
import { SpinnerService } from 'app/spinner/spinner.service';
import { UploadSpinnerService } from 'app/upload-spinner/upload-spinner.service';
import { environment } from 'environments/environment';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, mergeMap, tap, timeout } from 'rxjs/operators';
import { LogEventService } from './LogEvent.service';
import { LogEventName } from 'app/shared/enum/log-event-name';
import { TitleService } from './title.service';
import { RemoteConfigService } from './remote-config.service';
import { Util } from '../utils';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    public http: HttpClient;
    public authToken: string;
    public serverUrl = environment.serverUrl;
    public genieServerUrl = environment.myGenieUrl;
    public craftServerUrl = environment.craftUrl;
    public subscription: any;
    public tokenRefreshApiCall = false;
    public currentUser: any = { first_name: '', last_name: '' };
    remUser: Array<any>;
    private readonly apiResponseTimeOut = 30000;
    logEventName: any = LogEventName;
    private subject = new Subject<void>();

    constructor(
        @Inject(HttpClient) http: HttpClient,
        public router: Router,
        public dataService: DataService,
        public notifService: NotificationService,
        public reloadService: ReloadBoxService,
        public snackbarService: SnackbarService,
        public subDomainService: SubDomainService,
        public uploadSpinnerService: UploadSpinnerService,
        public spinnerService: SpinnerService,
        public translateService: TranslateService,
        public permission: PermissionService,
        private readonly angularFireMessaging: AngularFireMessaging,
        private readonly titleService: TitleService,
        public logEventService: LogEventService,
        public remoteConfigService: RemoteConfigService,
    ) {
        this.http = http;
        this.serverUrl = this.serverUrl.replace(/{domain}/g, this.subDomainService.getSubDomain());
        this.craftServerUrl = this.craftServerUrl.replace(
            /{domain}/g,
            this.subDomainService.getSubDomain(),
        );
    }

    setHeader(isFileUpload = false) {
        const token = JSON.parse(localStorage.getItem('auth_token'));
        if (token) {
            if (!this.tokenRefreshApiCall) {
                this.getAuthorizationHeader();
            }
            this.authToken = `${token.token_type} ${token.access_token}`;
            if (isFileUpload) {
                return new HttpHeaders().set('Authorization', this.authToken);
            } else {
                return new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Authorization', this.authToken);
            }
        } else {
            return new HttpHeaders().set('Content-Type', 'application/vnd.api+json');
        }
    }

    getAuthorizationHeader() {
        const token = JSON.parse(localStorage.getItem('auth_token'));
        if (
            token &&
            this.refreshToken(token.access_token) &&
            this.isTokenNotExpired(token.access_token)
        ) {
            this.tokenRefreshApiCall = true;
            const body = {
                refresh_token: token.refresh_token,
            };
            this.http
                .post(`${this.serverUrl}token/refresh`, body)
                .pipe(timeout(this.apiResponseTimeOut))
                .toPromise()
                .then(
                    (res: any) => {
                        localStorage.setItem('auth_token', JSON.stringify(res));
                        this.tokenRefreshApiCall = false;
                    },
                    (err) => {
                        this.deleteFcmToken();
                        this.logout();
                    },
                );
        }
    }

    options(option: any) {
        let options = option;
        if (options !== null && typeof options === 'object') {
            options.show_error =
                typeof options.show_error !== 'undefined' ? options.show_error : true;
            options.show_success =
                typeof options.show_success !== 'undefined' ? options.show_success : false;
            options.show_loader =
                typeof options.show_loader !== 'undefined' ? options.show_loader : true;
            options.show_toast =
                typeof options.show_toast !== 'undefined' ? options.show_toast : true;
        } else {
            options = {
                show_loader: false,
                show_success: false,
                show_error: true,
                show_toast: true,
            };
        }
        return options;
    }
    get(url: string, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http
            .get(this.serverUrl + url, {
                params: options.search,
            })
            .pipe(
                timeout(this.apiResponseTimeOut),
                tap((res) => this.handleSuccess(res, options)),
                catchError((err: any) => {
                    if (options.show_toast) {
                        this.handleError(err, options);
                    } else {
                        this.spinnerService.stop();
                        this.uploadSpinnerService.stop();
                    }
                    return observableThrowError(err || 'Server error');
                }),
            );
    }
    getText(url: string, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http
            .get(this.serverUrl + url, {
                responseType: 'text',
                params: options.search,
            })
            .pipe(
                timeout(this.apiResponseTimeOut),
                tap((res) => this.handleSuccess(res, options)),
                catchError((err: any) => {
                    if (options.show_toast) {
                        this.handleError(err, options);
                    } else {
                        this.spinnerService.stop();
                        this.uploadSpinnerService.stop();
                    }
                    return observableThrowError(err || 'Server error');
                }),
            );
    }
    getFile(url: string, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http
            .get(this.serverUrl + url, {
                responseType: 'blob',
                params: options.search,
            })
            .pipe(
                timeout(this.apiResponseTimeOut),
                tap((res) => this.handleSuccess(res, options)),
                catchError((err) => this.handleError(err, options)),
            );
    }
    put(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http.put(this.serverUrl + url, data).pipe(
            timeout(this.apiResponseTimeOut),
            tap((res) => this.handleSuccess(res, options)),
            catchError((err: any) => {
                if (options.show_toast) {
                    this.handleError(err, options);
                } else {
                    this.spinnerService.stop();
                    this.uploadSpinnerService.stop();
                }
                return observableThrowError(err || 'Server error');
            }),
        );
    }
    patch(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http.patch(this.serverUrl + url, data).pipe(
            timeout(this.apiResponseTimeOut),
            tap((res) => this.handleSuccess(res, options)),
            catchError((err: any) => {
                if (options.show_toast) {
                    this.handleError(err, options);
                } else {
                    this.spinnerService.stop();
                    this.uploadSpinnerService.stop();
                }
                return observableThrowError(err || 'Server error');
            }),
        );
    }
    post(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http.post(this.serverUrl + url, data).pipe(
            timeout(this.apiResponseTimeOut),
            tap((res) => this.handleSuccess(res, options)),
            catchError((err: any) => {
                if (options.show_toast) {
                    this.handleError(err, options);
                } else {
                    this.spinnerService.stop();
                    this.uploadSpinnerService.stop();
                }
                return observableThrowError(err || 'Server error');
            }),
        );
    }
    delete(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http
            .request('delete', this.serverUrl + url, {
                body: data,
            })
            .pipe(
                timeout(this.apiResponseTimeOut),
                tap((res) => this.handleSuccess(res, options)),
                catchError((err) => this.handleError(err, options)),
            );
    }
    uploadFile(url: string, myFormData: any, options?: any) {
        options = this.options(options);
        if (options.show_uploading) {
            this.uploadSpinnerService.start('UPLOADING');
        }
        if (options.show_submitting) {
            this.uploadSpinnerService.start('SUBMITTING');
        }
        return this.http
            .post(this.serverUrl + url, this.transformUploadFileFormData(myFormData))
            .pipe(
                tap((res) => this.handleSuccess(res, options)),
                catchError((err) => this.handleError(err, options)),
            );
    }
    private transformUploadFileFormData(myFormData) {
        let formData = new FormData();
        for (const key in myFormData) {
            if (Object.prototype.hasOwnProperty.call(myFormData, key)) {
                const value = myFormData[key];
                if (Array.isArray(value)) {
                    formData = this.transformArray2Formdata(formData, key, value);
                } else {
                    formData = this.transformObj2Formdata(formData, key, value);
                }
            }
        }
        return formData;
    }
    private transformArray2Formdata(formData, key, value) {
        if (key === 'translations') {
            for (let i = 0; i < value.length; i++) {
                const arrayKey = `${key}[${i}]`;
                
                for (const objKey in value[i]) {
                    formData.append(`${arrayKey}[${objKey}]`, value[i][objKey]);
                }
            }
        } else {
            const arrayKey = `${key}[]`;
            for (const arrayValue of value) {
                formData.append(arrayKey, arrayValue);
            }
        }
        return formData;
    }
    private transformObj2Formdata(formData, key, value) {
        if (key === 'thumbnail') {
            for (const objKey in value) {
                if (Object.prototype.hasOwnProperty.call(value, objKey)) {
                    const thumbnailKey = `${key}[${objKey}]`;
                    formData.append(thumbnailKey, value[objKey]);
                }
            }
        } else {
            formData.append(key, value);
        }
        return formData;
    }
    private handleSuccess(response: any, options: any) {
        if (options.show_loader) {
            this.spinnerService.stop();
            this.uploadSpinnerService.stop();
        }
        return response;
    }
    public handleError(error: any, options: any) {
        if (options.show_loader) {
            this.spinnerService.stop();
            this.uploadSpinnerService.stop();
        }
        const errorMsg = error;
        const body = errorMsg.error;
        if (navigator.onLine && error.status === 0) {
            this.snackbarService.error('Sorry something went wrong, Please try again later.');
        }
        if (navigator.onLine && body) {
            switch (body.code) {
                case 1002: {
                    this.snackbarService.error(body.message);
                    this.router.navigate(['/undermaintenance']);
                    break;
                }
                case 1004: {
                    this.snackbarService.error(body.message);
                    this.refreshUserProfile();
                    break;
                }
                case 1101: {
                    if (options.show_toast) {
                        this.snackbarService.error('User not found.');
                    }
                    break;
                }
                case 1102: {
                    if (options.show_toast) {
                        this.snackbarService.error('Incorrect password.');
                    }
                    break;
                }
                case 1103:
                case 1104: {
                    this.snackbarService.error('Unauthenticated request, Please login again.');
                    this.deleteFcmToken();
                    this.logout();
                    break;
                }
                case 1105: {
                    this.snackbarService.error(
                        this.translateService.instant(
                            'LEARNER.VALIDATION.INVALID_VERIFICATION_CODE',
                        ),
                    );
                    break;
                }
                case 1106: {
                    this.snackbarService.error('Invalid domain or principal.!!');
                    break;
                }
                case 1107: {
                    this.snackbarService.error(
                        this.translateService.instant('LEARNER.VALIDATION.INVALID_OLD_PW'),
                    );
                    break;
                }
                case 1401: {
                    this.snackbarService.error('Quiz not found.');
                    break;
                }
                case 1504: {
                    this.snackbarService.error(
                        this.translateService.instant('LEARNER.QUES_NOT_AVAIL'),
                    );
                    break;
                }
                case 2701: {
                    this.snackbarService.error('Test not found.');
                    break;
                }
                case 2706: {
                    this.snackbarService.error(
                        this.translateService.instant('LEARNER.TEST_TAKEN_DIFF_DEVICE'),
                    );
                    break;
                }
                case 2707: {
                    this.snackbarService.error(
                        this.translateService.instant('LEARNER.TEST_EXPIRED'),
                    );
                    break;
                }
                case 2708: {
                    this.snackbarService.error(
                        this.translateService.instant('LEARNER.TEST_TIME_END'),
                    );
                    break;
                }
                case 1009:
                case 4702:
                case 4703:
                case 4704:
                case 4705:
                case 4706:
                case 4707:
                case 5104:
                case 5308:
                case 99001:
                case 99002:
                case 99003:
                case 99004:
                case 99005:
                case 99006:
                case 99007:
                case 99008: {
                    break;
                }

                default: {
                    if (body.message) {
                        this.snackbarService.error(body.message);
                    }
                    break;
                }
            }
        }
        return observableThrowError(errorMsg || 'Server error');
    }
    refreshUserProfile() {
        this.currentUser = JSON.parse(localStorage.getItem('current_user'));
        const tempUser = {
            org: this.currentUser.org,
            type: this.currentUser.type,
            principal: this.currentUser.principal,
        };
        this.get('profile', { show_success: false, show_loader: false }).subscribe({
            next: (data: any) => {
                if (this.currentUser && this.currentUser['user_id'] === data['user_id']) {
                    this.currentUser = data;
                    this.currentUser.org = tempUser.org;
                    this.currentUser.type = tempUser.type;
                    this.currentUser.principal = tempUser.principal;
                    localStorage.setItem('current_user', JSON.stringify(this.currentUser));
                    this.permission.reload();
                }
            },
            error: (err: any) => {
                if (err.status === 0) {
                    this.snackbarService.offline();
                }
            },
        });
    }
    logout() {
        this.tokenRefreshApiCall = false;
        const remUser = JSON.parse(localStorage.getItem('remember_user'));
        const updatedUser = JSON.parse(localStorage.getItem('updatedUser'));
        const appUpdateAvailable = JSON.parse(localStorage.getItem('newVersionAvailable'));
        const isOffline = JSON.parse(localStorage.getItem('offline'));
        const localTimer = JSON.parse(localStorage.getItem('localTimer'));
        const resetFlag = JSON.parse(localStorage.getItem('resetFlag'));
        const ssoErrorMessage = localStorage.getItem('sso_error_message');
        const subscriptionId = localStorage.getItem('subscriptionId');
        const appInitDateTime = localStorage.getItem('appInitDateTime');
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('remember_user', JSON.stringify(remUser));
        localStorage.setItem('updatedUser', JSON.stringify(updatedUser));
        localStorage.setItem('appInitDateTime', appInitDateTime);
        if (appUpdateAvailable) {
            localStorage.setItem('newVersionAvailable', 'true');
        }
        if (isOffline) {
            localStorage.setItem('offline', JSON.stringify(isOffline));
        }
        if (localTimer) {
            localStorage.setItem('localTimer', JSON.stringify(localTimer));
        }
        if (resetFlag) {
            localStorage.setItem('resetFlag', 'true');
        }
        if (ssoErrorMessage) {
            localStorage.setItem('sso_error_message', ssoErrorMessage);
        }
        if (subscriptionId) {
            localStorage.setItem('subscriptionId', subscriptionId);
        }
        this.dataService.removeAll();
        // --------- clearing notification subscriptions ---------- //
        this.notifService.subscriptions.forEach((s) => s.unsubscribe());
        this.notifService.notifWindow = [];
        this.notifService.queryable = true;
        this.router.navigate(['/security/login']);
    }

    refreshToken(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const jwt = JSON.parse(window.atob(base64));
        const exp = jwt.exp;
        const issueAt = jwt.iat;
        const expDate = new Date(exp * 1000);
        const issueDate = new Date(issueAt * 1000);
        const validity: any = expDate.getTime() - issueDate.getTime();
        const refresh = exp - (validity * (20 / 100)) / 1000;
        const diff = Math.floor(refresh - new Date().getTime() / 1000);
        return diff < 0;
    }

    isTokenNotExpired(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const jwt = JSON.parse(window.atob(base64));
        const exp = jwt.exp;
        const newDate = new Date();
        const diff = exp - newDate.getTime() / 1000;
        return diff > 0;
    }

    deleteFcmToken() {
        this.angularFireMessaging.getToken
            .pipe(mergeMap((token) => this.angularFireMessaging.deleteToken(token)))
            .subscribe((token) => {
                console.log('Token deleted!');
            });
    }

    applySubscription(subscriptionUniqueId) {
        this.http
            .post(`${this.serverUrl}subscriptions/subscribe/${subscriptionUniqueId}`, {})
            .pipe(timeout(this.apiResponseTimeOut))
            .toPromise()
            .then(
                (res: any) => {
                    this.logEventService.logEvent(this.logEventName.AUTO_SUBSCRIPTION_SUCCESS, {});
                    localStorage.removeItem('subscriptionId');
                },
                (err) => {
                    this.handleError(err, { show_loader: true });
                    localStorage.removeItem('subscriptionId');
                    this.logEventService.logEvent(this.logEventName.AUTO_SUBSCRIPTION_ERROR, {});
                },
            );
    }

    setScheduleOrgRefreshAt() {
        this.remoteConfigService
            .getNumberValueByKey('org_cache_max_age_in_seconds')
            .then((value) => {
                const currDateTime = new Date();
                currDateTime.setSeconds(currDateTime.getSeconds() + value);
                localStorage.setItem('scheduleOrgRefreshAt', currDateTime.toString());
            });
    }

    checkOrgSettingMaxAge() {
        const lastAppInitTime = localStorage.getItem('scheduleOrgRefreshAt');
        if (lastAppInitTime !== null) {
            const currentDateTime = new Date();
            const lastAppInitTime = new Date(localStorage.getItem('scheduleOrgRefreshAt'));
            const differenceInSec = Math.floor(
                (currentDateTime.getTime() - lastAppInitTime.getTime()) / 1000,
            );
            if (differenceInSec >= 0) {
                localStorage.removeItem('scheduleOrgRefreshAt');
                this.refreshOrgSetting();
            }
        }
    }

    refreshOrgSetting() {
        const domain = this.subDomainService.getSubDomain();
        if (domain) {
            const body = { domain: domain };
            const options = { show_success: true, show_loader: true };
            this.post('login/lookup/org', body, options).subscribe({
                next: (data: any) => {
                    this.setScheduleOrgRefreshAt();
                    localStorage.setItem('orgSetting', JSON.stringify(data));
                    this.notifyChange();
                    this.titleService.setDocTitle(this.getOrg().name + ' | RapL');
                    const orgTheme = Util.filterArray(
                        this.getOrg().settings,
                        'property',
                        'org_theme',
                    ).value;
                    if (orgTheme !== null) {
                        Util.applytheme(orgTheme);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                },
            });
        }
    }

    notifyChange() {
        this.subject.next();
    }

    getChangeNotification(): Observable<void> {
        return this.subject.asObservable();
    }

    getGenie(url: string, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http
            .get(this.genieServerUrl + url, {
                params: options.search,
            })
            .pipe(
                timeout(this.apiResponseTimeOut),
                tap((res) => this.handleSuccess(res, options)),
                catchError((err: any) => {
                    if (options.show_toast) {
                        this.handleError(err, options);
                    } else {
                        this.spinnerService.stop();
                        this.uploadSpinnerService.stop();
                    }
                    return observableThrowError(err || 'Server error');
                }),
            );
    }

    postGenie(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http.post(this.genieServerUrl + url, data).pipe(
            timeout(this.apiResponseTimeOut),
            tap((res) => this.handleSuccess(res, options)),
            catchError((err: any) => {
                if (options.show_toast) {
                    this.handleError(err, options);
                } else {
                    this.spinnerService.stop();
                    this.uploadSpinnerService.stop();
                }
                return observableThrowError(err || 'Server error');
            }),
        );
    }

    putGenie(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http.put(this.genieServerUrl + url, data).pipe(
            timeout(this.apiResponseTimeOut),
            tap((res) => this.handleSuccess(res, options)),
            catchError((err: any) => {
                if (options.show_toast) {
                    this.handleError(err, options);
                } else {
                    this.spinnerService.stop();
                    this.uploadSpinnerService.stop();
                }
                return observableThrowError(err || 'Server error');
            }),
        );
    }

    deleteGenie(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http
            .request('delete', this.genieServerUrl + url, {
                body: data,
            })
            .pipe(
                timeout(this.apiResponseTimeOut),
                tap((res) => this.handleSuccess(res, options)),
                catchError((err) => this.handleError(err, options)),
            );
    }

    getOrg() {
        return JSON.parse(localStorage.getItem('orgSetting'));
    }

    getCraft(url: string, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http
            .get(this.craftServerUrl + url, {
                params: options.search,
            })
            .pipe(
                timeout(this.apiResponseTimeOut),
                tap((res) => this.handleSuccess(res, options)),
                catchError((err: any) => {
                    if (options.show_toast) {
                        this.handleError(err, options);
                    } else {
                        this.spinnerService.stop();
                        this.uploadSpinnerService.stop();
                    }
                    return observableThrowError(err || 'Server error');
                }),
            );
    }

    postCraft(url: string, data: any, options?: any) {
        options = this.options(options);
        if (options.show_loader) {
            this.spinnerService.start();
        }
        return this.http.post(this.craftServerUrl + url, data).pipe(
            timeout(this.apiResponseTimeOut),
            tap((res) => this.handleSuccess(res, options)),
            catchError((err: any) => {
                if (options.show_toast) {
                    this.handleError(err, options);
                } else {
                    this.spinnerService.stop();
                    this.uploadSpinnerService.stop();
                }
                return observableThrowError(err || 'Server error');
            }),
        );
    }
}
